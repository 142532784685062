.calendar {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }
  
  .calendar-header {
    width: 100%;
    height: 50px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .title {
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  .title h2 {
    margin: auto;
  }
  
  .tools {
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  .tools button {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: #ffffff;
    border: none;
  }
  
  .tools button span:hover {
    color: #99cccc;
  }
  
  .calendar-body {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  
  .table-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  
  .weekday {
    width: 100px;
    text-align: center;
  }
  
  .table-content {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    box-sizing: border-box;
  }
  
  .calendar-day {
    width: 14%;
    min-height: 80px;
    position: relative;
    border: 1px solid #a6a6a6;
  }
  
  .calendar-day:hover {
    background-color: rgba(0,0,0,.10);
  }
  
  .calendar-day p {
    position: absolute;
    right: 10px;
    color: #a6a6a6;
  }
  
  .current p {
    color: #000000;
  }
  
  .selected p {
    /* color: #cc0000; */
    font-weight: bold;
  }
  
  .selected {
    background-color: rgba(0,0,0,.30);
  }