.card{
  background-color: #161616;
  position: absolute;
  padding: 40px;
  left: 50%;
  top: 50%;
  /* text-align: center; */
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  box-sizing: content-box;
  box-shadow: 0 15px 25px rgba(0,0,0,.5);
  border-radius: 10px;
  width: 300px;
}

.body{
  background-image: url("./pan.jpg");
  background-size: cover;
  height: 100%;
  width: 100%;
  position:fixed;
}

.img{
  width: 200px;
  margin-bottom: 20px;
}

.imgPonct{
  width: 50px;
}

.div-img{
  width: 100%;
  text-align: center;
}

.bottom{
  position: absolute;
  /* padding: 40px; */
  right: 0;
  bottom: 0;
  box-sizing: content-box;
  width: 100px;
  font-size: 10px;
}